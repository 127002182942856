@import '../../../node_modules/two-app-ui/build/esm/src/scss/theme/variables';

#app_toolbar{
  flex: 0 1 auto;
  background: $shade300;
  .p-toolbar-group-left{
    width: 100%;
  }
  .app-toolbar-part{
    flex: 1 1 0;
    &.app-toolbar-center-part{
      flex-grow: 2;
    }
  }
}
